<!-- 在线直播 -->
<template>
  <div class="liveonlin">
    <liveonlinhead @exportVideoPlay='hotvideoplay' :doneplay='hotplay' :videoData='videoDatahead' v-if='videoDatahead'></liveonlinhead>
    <liveonlinexplain @exportDonePlay='donePlay' class='liveonlinexplain' :lists='explainData' :VideoNameEnglish='VideoNameEnglish'
    :titls='exptit'></liveonlinexplain>
    <liveonlinlist v-if='moreMovie&&moreMovie.length>0' :key='index' v-for='item,index in moreMovie' class='liveonlinlist' @exportDonePlay='donePlay' :datalist='item.list'  :title='item.VideoName'></liveonlinlist>
    <div class="btmbox">
      <infiniteImg type='0' class='infiniteImg'></infiniteImg>
    </div>
  </div>
</template>
<script>
import infiniteImg from '@/components/infiniteImg/infiniteImg.vue';//底部滚动展---他们都选微微定
import liveonlinhead from '@/components/liveonlin-head/liveonlin-head.vue';//在线直播---头部
import liveonlinexplain from '@/components/liveonlin-explain/liveonlin-explain.vue';//在线直播---好货讲解
import liveonlinlist from '@/components/liveonlin-list/liveonlin-list.vue';//在线直播---列表
export default {
  name: 'liveonlin',
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
    return {
      htmltitle:'在线直播-微微定',
      hotplay:{play:true},//顶部视频开关变量
      // datalist:[
      //   {url:require('../assets/img/liveonlin/index_video1.mp4'),name:'便宜竹浆牙签定制酒店KTV餐厅...',time:'2019/12/28',number:6748,_play:false},
      //   {url:require('../assets/img/liveonlin/index_video1.mp4'),name:'便宜竹浆牙签定制酒店KTV餐厅...',time:'2019/12/28',number:6748,_play:false},
      //   {url:require('../assets/img/liveonlin/index_video1.mp4'),name:'便宜竹浆牙签定制酒店KTV餐厅...',time:'2019/12/28',number:6748,_play:false},
      //   {url:require('../assets/img/liveonlin/index_video1.mp4'),name:'便宜竹浆牙签定制酒店KTV餐厅...',time:'2019/12/28',number:6748,_play:false},
      //   {url:require('../assets/img/liveonlin/index_video1.mp4'),name:'便宜竹浆牙签定制酒店KTV餐厅...',time:'2019/12/28',number:6748,_play:false},
      //   {url:require('../assets/img/liveonlin/index_video1.mp4'),name:'便宜竹浆牙签定制酒店KTV餐厅...',time:'2019/12/28',number:6748,_play:false},
      //   {url:require('../assets/img/liveonlin/index_video1.mp4'),name:'便宜竹浆牙签定制酒店KTV餐厅...',time:'2019/12/28',number:6748,_play:false},
      //   {url:require('../assets/img/liveonlin/index_video1.mp4'),name:'便宜竹浆牙签定制酒店KTV餐厅...',time:'2019/12/28',number:6748,_play:false},
      //   {url:require('../assets/img/liveonlin/index_video1.mp4'),name:'便宜竹浆牙签定制酒店KTV餐厅...',time:'2019/12/28',number:6748,_play:false},
      //   {url:require('../assets/img/liveonlin/index_video1.mp4'),name:'便宜竹浆牙签定制酒店KTV餐厅...',time:'2019/12/28',number:6748,_play:false},
      //   {url:require('../assets/img/liveonlin/index_video1.mp4'),name:'便宜竹浆牙签定制酒店KTV餐厅...',time:'2019/12/28',number:6748,_play:false},
      //   {url:require('../assets/img/liveonlin/index_video1.mp4'),name:'便宜竹浆牙签定制酒店KTV餐厅...',time:'2019/12/28',number:6748,_play:false},
      //   {url:require('../assets/img/liveonlin/index_video1.mp4'),name:'便宜竹浆牙签定制酒店KTV餐厅...',time:'2019/12/28',number:6748,_play:false},
      //   {url:require('../assets/img/liveonlin/index_video1.mp4'),name:'便宜竹浆牙签定制酒店KTV餐厅...',time:'2019/12/28',number:6748,_play:false},
      //   {url:require('../assets/img/liveonlin/index_video1.mp4'),name:'便宜竹浆牙签定制酒店KTV餐厅...',time:'2019/12/28',number:6748,_play:false},
      //   {url:require('../assets/img/liveonlin/index_video1.mp4'),name:'便宜竹浆牙签定制酒店KTV餐厅...',time:'2019/12/28',number:6748,_play:false},
      //   {url:require('../assets/img/liveonlin/index_video1.mp4'),name:'便宜竹浆牙签定制酒店KTV餐厅...',time:'2019/12/28',number:6748,_play:false},
      //   {url:require('../assets/img/liveonlin/index_video1.mp4'),name:'便宜竹浆牙签定制酒店KTV餐厅...',time:'2019/12/28',number:6748,_play:false},
      //   {url:require('../assets/img/liveonlin/index_video1.mp4'),name:'便宜竹浆牙签定制酒店KTV餐厅...',time:'2019/12/28',number:6748,_play:false},
      //   {url:require('../assets/img/liveonlin/index_video1.mp4'),name:'便宜竹浆牙签定制酒店KTV餐厅...',time:'2019/12/28',number:6748,_play:false},
      // ],//视频列表
      videoDatahead:null,//头部视频列表
      explainData:null,//好货讲解
      exptit:null,//好货讲解标题
      VideoNameEnglish:null,//好货讲解视频
      datalist:null,//视频列表
      listtit:null,//视频列表标题
      moreMovie:null,//跟多视频列表
    }
  },
  components: {
    infiniteImg,
    liveonlinhead,
    liveonlinexplain,
    liveonlinlist
  },
  props: {
    msg: String
  },
  methods:{
    donePlay(){
      // 关闭banner视频播放
      this.hotplay = {play:false}
    },
    hotvideoplay(){
      // 监听banner视频播放
      this.hotplay = {play:true}
      // console.log(this.hotplay)
    },
    localReset(){
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'OnLinePlay/GetOnLinePlayData',this.keys())
        .then(res => {
            if(res.data.Success){
              var parent = [];
              for(let i in res.data.Data.List){
                if(res.data.Data.List[i].ParentID==0){
                  parent.push(res.data.Data.List[i])
                }
              }
              for(let i in parent){
                parent[i].list = [];
                for(let j in res.data.Data.List){
                  if(parent[i].Id==res.data.Data.List[j].ParentID){
                    var _obj = {};
                    _obj.tip = 0
                    _obj.number = 0
                    _obj._play = false
                    _obj.url = this._src+ res.data.Data.List[j].VideoPath
                    _obj.name = res.data.Data.List[j].VideoName
                    _obj.urlImg =this._src+ res.data.Data.List[j].VideoIMG
                    _obj.time =res.data.Data.List[j].CreateDate?res.data.Data.List[j].CreateDate.split('T')[0]:''
                    parent[i].list.push(_obj)
                  }
                }
              }
              // console.log(parent)
              if(parent&&parent.length>0&&parent[0]){
                this.$set(this,'videoDatahead',parent[0].list)
              }
              if(parent&&parent.length>0&&parent[1]){
                this.$set(this,'explainData',parent[1].list)
                this.$set(this,'exptit',parent[1].VideoName)
                this.$set(this,'VideoNameEnglish',parent[1].VideoNameEnglish)
              }
              if(parent&&parent.length>1){
                var _data = [];
                for(let i =2;i<parent.length;i++){
                  _data.push(parent[i])
                }
                this.$set(this,'moreMovie',_data)
                // this.$set(this,'datalist',parent[2].list)
                // this.$set(this,'listtit',parent[2].VideoName)
              }
              if(parent[0].SeoTitle){
                document.title = parent[0].SeoTitle
              }
              if(parent[0].SeoDescrition){
                $('meta[name=description]').attr('content',parent[0].SeoDescrition)
              }
              if(parent[0].SeoKey){
                 $('meta[name=Keywords]').attr('content',parent[0].SeoKey)
              }
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b,_pomise.c])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.localReset();
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.liveonlin {
  .liveonlinexplain {
    width: 1200px;margin:0 auto;margin-top:40px;
  }
  .btmbox {
    width: 1200px;margin:0 auto;
  }
  .liveonlinlist {
    width: 1200px;margin:0 auto;padding-bottom:40px;
  }
}
</style>
