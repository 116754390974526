<!-- 在线直播-好货讲解 -->
<template>
  <div class="liveonlin-explain" v-if='lists&&lists.length>0'>
    <div class="title">
      <span>{{VideoNameEnglish}}</span>
      <h6>{{titls}}</h6>
    </div>
    <div class="list">
      <div class="item" v-for='i,n in lists' :key='n'>
        <div class="tip" v-if='i.tip==1'>
          <svg class="icon"><use xlink:href="#iconziyuan6"></use></svg>
        </div>
        <div class="img">
          <!-- <video :src="i.url1" >
            您的浏览器不支持 video 标签。
            </video> -->
            <img :src="i.urlImg">
        </div>
        <div class="info">
          <p>{{i.name}}</p>
          <span>{{i.time}}</span>
        </div>
        <div class="play" @click='plays(i,n)'>
          <span><svg class="icon"><use xlink:href="#iconxiangshangxialakuang"></use></svg></span>
        </div>
        <div class="popplay" :class='i._play?"popplay1":""'>
          <div class="box">
            <button @click.stop='donePlay' class="closepop"><svg><use xlink:href="#iconshanchu"></use></svg></button>
            <video :src="i.url" controls="controls" ref='video' >
            您的浏览器不支持 video 标签。
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'liveonlin-explain',
  data(){
    return {
      // lists:[
      //   {url:require('../../assets/img/liveonlin/img1.jpg'),name:'纸为你来 定制纸巾讲解',time:'2019/12/27',tip:1,url1:require('../../assets/img/liveonlin/index_video1.mp4'),_play:false},
      //   {url:require('../../assets/img/liveonlin/img1.jpg'),name:'纸为你来 定制纸巾讲解',time:'2019/12/27',tip:0,url1:require('../../assets/img/liveonlin/index_video1.mp4'),_play:false},
      //   {url:require('../../assets/img/liveonlin/img1.jpg'),name:'纸为你来 定制纸巾讲解',time:'2019/12/27',tip:1,url1:require('../../assets/img/liveonlin/index_video.mp4'),_play:false},
      //   {url:require('../../assets/img/liveonlin/img1.jpg'),name:'纸为你来 定制纸巾讲解',time:'2019/12/27',tip:0,url1:require('../../assets/img/liveonlin/index_video.mp4'),_play:false},
      // ],
    }
  },
  props: {
    lists: null,//好货讲解数据
    titls:null,//标题
    VideoNameEnglish:null,//英文
  },
  methods:{
    plays(item,index){
      this.$emit('exportDonePlay');//暂停banner置顶视频
      for(let i in this.$refs.video){
        // 暂停组件内其他视频
        this.$refs.video[i].pause()
      }
      // 重置列表显示视频
      for(let i in this.lists){
        this.lists[i]._play = false
      }
      this.lists[index]._play = true;
      this.$refs.video[index].play()
    },
    donePlay(){
      // 关闭组件弹窗播放
      for(let i in this.$refs.video){
        // 暂停组件内其他视频
        this.$refs.video[i].pause()
      }
      // 重置列表显示视频
      for(let i in this.lists){
        this.lists[i]._play = false
      }
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // console.log(this.$refs.vo)
      // this.$refs.vo[0].addEventListener('contextmenu',function(){return false;})
    })
  }
}
</script>
<style  lang="scss" scoped>
.liveonlin-explain {
  .title {
    padding-bottom:55px;
    span {
      font-size: 36px;font-weight: bold;color:#e1e1e1;display: block;margin:0 auto;text-align: center;
    }
    h6 {
      width: 570px;display: block;margin:0 auto;text-align: center;margin-top:-26px;font-size: 26px;color:#373737;position: relative;
      &:after,&:before {
        content: '';position: absolute;display: block;width: 225px;height: 1px;bottom:-20px;
      }
      &:after {
        left:0px;background-image: linear-gradient(to right, #96183e , rgba(0,0,0,0));
      }
      &:before {
        right:0px;background-image: linear-gradient(to right, rgba(0,0,0,0) , #96183e);
      }
    }
  }
  .list {
    // display: flex;justify-content: space-between;
    zoom:1;
    .item {
      width: calc((100% - 60px)/4);height: 210px;position: relative;cursor: pointer;overflow: hidden;float:left;margin-right: 20px;margin-bottom:20px;
      .tip {
        position: absolute;top:0px;left:0px;z-index: 7;
        svg {
          width:60px;height: 60px;
        }
      }
      .img {
        width: 100%;height: 210px;display:flex;justify-content: center;align-items: center;background: #fff;
        img {
          display: block;width: 175px;height: 155px;
        }
        video {
          width: 100%;height: 100%; object-fit: fill;
        }
      }
      .info {
        // bottom:-40px;
        position: absolute;bottom:0px;left:0px;width: 100%;height: 40px;background:#343434;color:#fff;z-index: 9;display: flex;justify-content: space-between;transition: bottom 0.1s;
        p {
          height: 40px;line-height: 40px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;width: 180px;padding-left:10px;font-size: 16px;
        }
        span {
          padding-right: 10px;height: 40px;line-height: 45px;font-size: 12px;
        }
      }
      .play {
        width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;position: absolute;top:0px;left:0px;z-index: 11;visibility: hidden;
        span {
          border:2px solid #fff;border-radius: 50%;display: block;width: 60px;height: 60px;display: flex;justify-content: center;align-items: center;
          svg {
            fill: #fff;width: 40px;height: 40px;transform: rotate(90deg);
          }
        }
      }
      &:after {
        content: '';display: block;width: 100%;height: 100%;position: absolute;top:0px;left:0px;background: rgba(0,0,0,0.4);z-index: 8;visibility: visible;
      }
      &:hover {
        .info {
          bottom:0px;
        }
        .play {
          visibility: visible;
        }
        &:after {
          visibility: visible;
        }
      }
      .popplay {
        position: fixed;top:0px;left:0px;z-index: 999;background:rgba(0,0,0,0.8);visibility: hidden;width: 100%;height: 100%;
        .box {
          width: 1200px;height: 600px;position: absolute;top:calc((100vh - 600px)/2);left:calc((100vw - 1200px)/2);display: flex;justify-content: center;align-items: center;
          video {
            width: 1000px;position: relative;z-index: 9999;object-fit: fill;height: 500px;
          }
          .closepop {
            position: absolute;top:0px;right:0px;width: 40px;height: 40px;background: none;
            svg {
              width: 26px;height: 26px;fill: #fff;
            }
          }
        }
      }
      .popplay1 {
        visibility: visible;
      }
      &:nth-child(4n+4){
        margin-right: 0px;
      }
    }
    &:after {
      content: '';display: block;clear:both;
    }
  }
}
</style>
