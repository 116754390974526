<!-- 在线直播-头部banner -->
<template>
  <div class="liveonlin-head" v-if='videoData&&videoData.length>0'>
    <div class="bn">
      <div class="box">
        <span class="sp"></span>
        <span class="sp1"></span>
        <span class="sp2"></span>
        <span class="sp3"></span>
        <div class="lt">
          <video name='video' :src="item.url" v-show='choiceIdx==index' autoplay="autoplay" muted controls="controls" ref='video' v-for='item,index in videoData' :key='index'>
          您的浏览器不支持 video 标签。
          </video>
     <!--      <video :src="url" controls="controls" ref='video' >
          您的浏览器不支持 video 标签。
          </video> -->
        </div>
        <div class="rt">
<!--           <div class="img">
            <img src="../../assets/img/liveonlin/img.jpg">
          </div> -->
          <div class="img1">
 <!--            <div class="d"><img src="../../assets/img/liveonlin/img1.jpg"></div>
            <div class="d"><img src="../../assets/img/liveonlin/img1.jpg"></div>
            <div class="d"><img src="../../assets/img/liveonlin/img1.jpg"></div>
            <div class="d"><img src="../../assets/img/liveonlin/img1.jpg"></div> -->
            <div  class="d" @click='choiceVideo(item,index)' :class='{choice:choiceIdx==index}' v-for='item,index in videoData'><img :src="item.urlImg"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'liveonlin-head',
  data(){
    return {
      url: require('../../assets/img/liveonlin/index_video.mp4'),
      choiceIdx:0,//默认选择索引
    }
  },
  props: {
    doneplay:Object,
    videoData:null,
  },
  methods:{
    choiceVideo(item,index){
      // 关闭全部视频
      for(let i in this.$refs.video){
          this.$refs.video[i].pause()
      }
      // 头部切换视频
      this.$refs.video[index].play()
      this.choiceIdx = index
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // console.log(1)
      // this.$refs.video.addEventListener('play',()=>{
      //   // 监听视频播放
      //   this.$emit('exportVideoPlay')
      // })
      for(let i in this.$refs.video){
          this.$refs.video[i].addEventListener('play',()=>{
            // 监听视频播放
            this.$emit('exportVideoPlay')
          })
      }
    })
  },
  watch:{
    'doneplay':function(n,o){
      // console.log(n,o)
      if(!n.play){
        // this.$refs.video.pause()
        for(let i in this.$refs.video){
            this.$refs.video[i].pause()
        }
      }
    },
    'videoData':function(n,o){
    }
  }
}
</script>
<style  lang="scss" scoped>
.liveonlin-head {
  .bn {
    width: 100%;height: 450px;background: url('../../assets/img/liveonlin/bn.jpg') center center no-repeat;
    .box {
      width: 1150px;margin:0 auto;height: 400px;border:25px solid #202020;border-radius: 10px;position: relative;display: flex;align-items: center;justify-content: space-between;
      &:after {
        width: 1200px;height:450px;position: absolute;top:-25px;left:-25px;box-shadow: 0px 0px 10px 12px #9ca0a3 inset;content: "";display: block;border-radius: 10px;
      }
      .sp {
        display: block;width: 1100px;height: 5px;position: absolute;top:-25px;right:-25px;border-top-right-radius: 10px;background-image: linear-gradient(to right, rgba(136,134,135,0.4), #e8130f,#fabf27,#61ce25,#04e0ff,#0900a0,#ff00d2);z-index: 9;
      }
      .sp1 {
        display: block;width: 1100px;height: 5px;position: absolute;bottom:-25px;left:-25px;border-bottom-left-radius: 10px;background-image: linear-gradient(to left, rgba(136,134,135,0.4), #e8130f,#fabf27,#61ce25,#04e0ff,#0900a0,#ff00d2);z-index: 9;
      }
      .sp2 {
        display: block;width: 5px;height: 443px;position: absolute;top:-25px;right:-25px;border-top-right-radius: 10px;background-image: linear-gradient(#e66465, #9198e5);z-index: 9;
      }
      .sp3 {
        display: block;width: 5px;height: 443px;position: absolute;bottom:-25px;left:-25px;border-bottom-left-radius: 10px;background-image: linear-gradient(#9198e5, #ff00d2);z-index: 9;
      }
      .lt {
        width: 800px;height: 400px;position: relative;z-index: 99;display: flex;justify-content: center; align-items: center;background: #fff;float:left;
        video {
          width: 800px;height: 400px;object-fit: fill;
        }
      }
      .rt {
        width: 345px;background: #1c1a1d;height: 400px;position: relative;z-index: 99;overflow-y: auto;
        &::-webkit-scrollbar{display:block;position:fixed;width:2px;background:white;}
        &::-webkit-scrollbar-thumb {background:#333;width:600px;}
        // .img {
        //   width: 360px;height: 150px;position: relative;cursor: pointer;
        //   img {
        //     display: block;width: 100%;height: 100%;
        //   }
        //   &:after {
        //     content: '';display: block;width: 100%;height: 100%;background: rgba(0,0,0,0.5);top:0px;left:0px;position: absolute;
        //   }
        //   &:hover:after {
        //     display: none;
        //   }
        // }
        .img1 {
          display: flex; flex-wrap:wrap;width: 328px;justify-content: space-between;
          .d {
            width: 159px;height: 140px;position: relative;cursor: pointer;margin-bottom:5px;
            img {
              display: block;width: 100%;height: 100%;
            }
            &:after {
              content: '';display: block;width: 100%;height: 100%;background: rgba(0,0,0,0.5);top:0px;left:0px;position: absolute;
            }
            &:hover:after {
              display: none;
            }
            &:nth-child(1) {
              width: 328px;height: 290px;
            }
          }
          .choice {
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
