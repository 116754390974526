<!-- 在线直播--列表 -->
<template>
  <div class="liveonlin-list" v-if='datalist&&datalist.length>0'>
    <h6 class="h6"><svg class="icon"><use xlink:href="#iconyunzhibo"></use></svg>{{title}}</h6>
    <div class="box">
      <div class="item" v-for='i,index in datalist' :key='index'  @click='plays(i,index)'>
        <div class="show">
          <!-- <video :src="i.url" >
          您的浏览器不支持 video 标签。
          </video> -->
          <img :src="i.urlImg">
        </div>
        <div class="info" >
          <h6>{{i.name}}</h6>
          <div class="p">
            <p>{{i.time}}</p>
            <!-- <p><svg class="icon"><use xlink:href="#iconyanjing"></use></svg>{{i.number}}</p> -->
          </div>
        </div>
        <div class="popplay" :class='{popplay1:index==indexC}'>
          <div class="box1">
            <button @click.stop='donePlay' class="closepop"><svg><use xlink:href="#iconshanchu"></use></svg></button>
            <video :src="i.url" controls="controls" ref='video'>
            您的浏览器不支持 video 标签。
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'liveonlin-list',
  data(){
    return {
      indexC:null,
    }
  },
  props: {
    datalist: Array,
    title:null,
  },
  methods:{
    plays(item,index){
      this.$emit('exportDonePlay');//暂停banner置顶视频
      for(let i in this.$refs.video){
        // 暂停组件内其他视频
        this.$refs.video[i].pause()
      }
      this.indexC = index
      this.$refs.video[index].play()
    },
    donePlay(){
      // 关闭组件弹窗播放
      for(let i in this.$refs.video){
        // 暂停组件内其他视频
        this.$refs.video[i].pause()
      }
      this.indexC =null
    }
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.liveonlin-list {
  .h6 {
    margin-top:20px;margin-bottom:20px;font-size: 22px;font-weight:normal;
    svg {
      width: 20px;height: 20px;vertical-align: -3px;margin-right: 5px;fill: #f69752;
    }
  }
  .box {
    display: flex;flex-wrap:wrap;justify-content: flex-start;
    .item {
      width: calc((100% - 60px)/5);position: relative;border-radius: 10px;overflow: hidden;margin-right: 15px;margin-bottom:15px;cursor: pointer;height: 170px;background: #fff;
      .show {
        display: flex;justify-content: center;align-items: center;width: 228px;height:170px;
        video {
          width: 100%;height: 120px; object-fit: fill;
        }
        img {
          width: 175px;height: 155px;
        }
      }
      .info {
        position: absolute;bottom:-50px;background: #fff;left:0px;height: 50px;width: 100%;transition: bottom 0.1s;
        h6 {
          overflow: hidden;text-overflow:ellipsis;white-space: nowrap;height: 30px;line-height: 30px;color:#333;padding:0 10px;font-size: 14px;font-weight: normal;
        }
        .p {
          display: flex;padding:0 10px;justify-content: space-between;
          p {
            color:#797979;font-size: 12px;
            svg {
              vertical-align: -4px;width: 18px;height: 18px;margin-right: 5px;fill: #797979;
            }
          }
        }
      }
      .popplay {
        position: fixed;top:0px;left:0px;z-index: 9999;background:rgba(0,0,0,0.8);visibility: hidden;width: 100%;height: 100%;
        .box1 {
          width: 1200px;height: 600px;position: absolute;top:calc((100vh - 600px)/2);left:calc((100vw - 1200px)/2);display: flex;justify-content: center;align-items: center;
          video {
            width: 1000px;position: relative;z-index: 9999;object-fit:fill;height: 500px;
          }
          .closepop {
            position: absolute;top:0px;right:0px;width: 40px;height: 40px;background: none;
            svg {
              width: 26px;height: 26px;fill: #fff;
            }
          }
        }
      }
      .popplay1 {
        visibility: visible;
      }
      &:nth-child(5n + 5) {
        margin-right:0px;
      }
      &:hover {
        .info {
          bottom:0px;
        }
        .show {
          video {
            transform: scale(1.2);
          }
          img {
            transform: scale(1.2);
          }
        }
      }
    }
  }
}
</style>
